<template>
    <div>
        <div v-if="currentDrivingLicense" class="card">
            <b-form @submit="updateDrivingLicense">
                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="currentDrivingLicense.name" placeholder=""></b-form-input>
                </b-form-group>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateDrivingLicense" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteDrivingLicense" :disabled="this.$store.state.loading"> <b-icon icon="trash"/></b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a DrivingLicense...' }}</p>
        </div>
    </div>
</template>

<script>
import DrivingLicenseDataService from "./DrivingLicenseDataService";

export default {
    name: "driving-license-edit",
    data() {
        return {
            currentDrivingLicense: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ["drivingLicenseId"],
    methods: {
        getDrivingLicense(id) {
            DrivingLicenseDataService.get(id)
                .then(response => {
                    this.currentDrivingLicense = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateDrivingLicense() {
            DrivingLicenseDataService.update(this.currentDrivingLicense.id, this.currentDrivingLicense)
                .then(response => {
                    this.message = 'Patente aggiornata correttamente!';
                    //setTimeout(() => this.$emit('updated-driving-license', this.currentDrivingLicense), 700)
                    this.$emit('updated-driving-license', this.currentDrivingLicense);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        archiveDrivingLicense() {
            if(confirm(`Vuoi archiviare la patente ${this.currentDrivingLicense.name}?`)) {
                DrivingLicenseDataService.archive(this.currentDrivingLicense.id)
                    .then(response => {
                        this.$emit('deleted-driving-license');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        deleteDrivingLicense() {
            if(confirm(`Vuoi cancellare la patente ${this.currentDrivingLicense.name}?`)) {
                DrivingLicenseDataService.delete(this.currentDrivingLicense.id)
                    .then(response => {
                        this.$emit('deleted-driving-license');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getDrivingLicense(this.drivingLicenseId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
