<template>
    <div>
        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h4>Patenti</h4>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentDrivingLicense = null">
                <b-icon icon="plus"/> Aggiungi Patente
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col" width="40">#</th>
                            <th scope="col">Nome</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': drivingLicense.id == currentId }"
                            v-for="drivingLicense in drivingLicenses"
                            :key="drivingLicense.id"
                            @click="setActiveDrivingLicense(drivingLicense)"
                        >
                            <td>{{ drivingLicense.id }}</td>
                            <td>{{ drivingLicense.name }}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <driving-license-edit :driving-license-id="currentDrivingLicense.id" :key="currentDrivingLicense.id"
                                  v-on:updated-driving-license="refreshCurrentDrivingLicense($event)"
                                  v-on:deleted-driving-license="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <driving-license-create
                        v-on:created-driving-license="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>




    </div>

</template>

<script>
import DrivingLicenseDataService from "./DrivingLicenseDataService";
import DrivingLicenseEdit from "./DrivingLicenseEdit";
import DrivingLicenseCreate from "./DrivingLicenseCreate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "driving-licenses-index",
    components: {DrivingLicenseEdit, DrivingLicenseCreate, SearchBar},
    data() {
        return {
            drivingLicenses: [],
            currentDrivingLicense: null,
            currentId: -1,
        };
    },
    methods: {
        retrieveDrivingLicenses() {
            DrivingLicenseDataService.getAll()
                .then(response => {
                    this.drivingLicenses = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveDrivingLicenses();
            this.currentDrivingLicense = null;
            this.currentId = -1;
        },

        refreshCurrentDrivingLicense(drivingLicenses) {
            this.drivingLicenses.forEach(function (item) {
                if (item.id == drivingLicenses.id) {
                    item.name = drivingLicenses.name;
                }
            });
            this.closeEditor();
        },

        setActiveDrivingLicense(drivingLicenses) {
            this.currentDrivingLicense = drivingLicenses;
            this.currentId = drivingLicenses.id;
        },

        removeAllDrivingLicenses() {
            DrivingLicenseDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentDrivingLicense = null;
            this.currentId = -1;
            DrivingLicenseDataService.findByName(name)
                .then(response => {
                    this.drivingLicenses = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentDrivingLicense = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrieveDrivingLicenses();
    }
};
</script>

<style>
</style>
